@import "variables";

$numMenuItems: 5;

div#header {
	border-bottom: 1px solid $baseColor1;
	margin-bottom: 30px;
	padding-top: 30px;
	padding-bottom: 20px;
	text-align: center;

	ul > li {
		display: inline-block;
		text-align: center;
		min-width: 60px;
		width: 100% / $numMenuItems; } }
